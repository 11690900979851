const subheadingsData = {
  1: [
    {
      title: "About Me",
      content: (
        <>
          <p>
            I am a JavaScript Developer. I use JS language and its associated
            libraries and frameworks to create visually engaging, highly
            interactive web applications. As a tech enthusiast who thrives on
            innovation, I am always looking forward to seeking opportunities
            that allow me to create, learn, and grow.
          </p>
        </>
      ),
    },
  ],
  2: [
    {
      title: "De Montfort University in Leicester, UK",
      content: (
        <>
          <p>
            <strong>Bachelor of Science in Computer Science</strong>
            <br />
            This degree provided me with a strong foundation in computer science
            fundamentals, from data structures and algorithms to computer
            systems and networks. I've enhanced my analytical skills and gained
            in-depth knowledge in software development and problem-solving.
            Additionally, the coursework in Web Development gave me the
            opportunity to learn and practice modern web technologies, creating
            a base for my future as a JavaScript Developer.
          </p>
        </>
      ),
    },
  ],
  3: [
    {
      title: "WordPress / Front-End Developer 2021.10-2024.08 ",
      content: (
        <>
          <p>
            I developed and customized WordPress themes based on client or
            design specifications, often from scratch or using page builders and
            I integrated third-party services like payment gateways. As a front
            end developer I developed e-commerce website that provided users
            from product discovery to checkout. I collaborated with back-end
            developers and UI/UX designers to implement product listing and
            filtering, responsive design or user authentication.
          </p>
        </>
      ),
    },
    {
      title: " IT Specialist Intern 2020.06-2020.08",
      content: (
        <>
          <p>
            I gained valuable experience in managing and supporting the
            company's IT infrastructure, which includes hardware, software,
            networks, and servers. My responsibilities were ranging from
            troubleshooting technical issues to supporting major IT projects.
          </p>
        </>
      ),
    },
  ],
};

export default subheadingsData;
