import React, { Component } from "react";

import AboutMenu from "./AboutMenu.js";

export default class About extends Component {
  render() {
    return (
      <>
        <AboutMenu />
      </>
    );
  }
}
